'use client';

import { useState } from 'react';
import Nprogress from 'nprogress';
import { Alert, Button, Form, FormGroup, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { signInWithEmailAndPassword } from 'firebase/auth';

import { FaEyeSlash, FaEye } from 'react-icons/fa';
import validateEmailAddress from '../../utils/validateEmailAddress';
import { auth } from '../../utils/firebase';
import SharedPasswordResetRequest from './PasswordResetRequest';
import capitalizeFirstLetter from '../../utils/capitalizeFirstLetter';
import handleUserLoginOrSignup from '../../utils/handleUserLoginOrSignup';
import { Dictionaries, Locale } from '../../i18n/i18n-config';
import { getI18nUtils } from '../../i18n/i18n-utils';

type Props = {
  emailAddress?: string;
  dictionary: Partial<Dictionaries>;
  locale: Locale;
};
function LoginWithEmail({ emailAddress, dictionary, locale }: Props): JSX.Element {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const emailParam = searchParams.get('email');
  const { formatMessage } = getI18nUtils(dictionary);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowpassword] = useState<boolean>(false);
  const [passwordResetForm, setPasswordResetForm] = useState<boolean>(false);

  const defaultInputs = {
    email: emailParam && typeof emailParam === 'string' ? emailParam : '',
    password: '',
  };
  if (emailAddress) defaultInputs.email = emailAddress;

  const [inputs, setInputs] = useState(defaultInputs);
  const [error, setError] = useState<string | null>(null);

  const { email, password } = inputs;

  const formValid = validateEmailAddress(email) && password !== '';
  const toggleViewPassword = () => setShowpassword((prev) => !prev);

  async function submitHandler(e) {
    e.preventDefault();
    Nprogress.start();

    setLoading(true);

    try {
      // @info: signin will trigger onIdTokenChanged in usercontext
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await handleUserLoginOrSignup({ userCredential, locale, pathname, router });
    } catch (error) {
      let errorMessage = 'internal-server-error';
      switch (error.code) {
        case 'auth/invalid-login-credentials':
        case 'auth/wrong-password':
          errorMessage = 'login-error-wrong-password';
          break;
        case 'auth/user-not-found':
          errorMessage = 'login-error-user-not-found';
          break;
        case 'auth/email-already-in-use':
          errorMessage = 'signup-error-email-in-use';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'login-error-too-many-request';
          break;
        case 'auth/weak-password':
          errorMessage = 'login-error-weak-password';
          break;
        case 'auth/invalid-password':
          errorMessage = 'login-error-invalid-password';
          break;
        default:
          errorMessage = error.message;
          break;
      }
      setError(errorMessage);
    }

    Nprogress.done();
    setLoading(false);
  }

  function handleInputChange({ target: { name, value } }) {
    setInputs({ ...inputs, [name]: value });
  }

  if (passwordResetForm) {
    return (
      <SharedPasswordResetRequest
        locale={locale}
        dictionary={dictionary}
        emailAddress={emailAddress}
        abort={() => setPasswordResetForm(false)}
      />
    );
  }

  return (
    <Form onSubmit={(e) => submitHandler(e)} autoComplete="on" id="login-with-email" className="w-100">
      <fieldset disabled={loading}>
        <FormGroup className="form-group position-relative">
          <Label>E-mail</Label>
          <Input
            className="w-100"
            type="email"
            name="email"
            id="email-signup"
            autoComplete="email"
            required
            placeholder={capitalizeFirstLetter(formatMessage({ id: 'item-input' }, { item: 'e-mail' }, true))}
            value={email}
            valid={validateEmailAddress(email)}
            onChange={(e) => handleInputChange(e)}
          />
        </FormGroup>

        <FormGroup className="form-group position-relative">
          <Label>{formatMessage({ id: 'password' })}</Label>
          <InputGroup>
            <Input
              // className="w-100"
              type={showPassword ? 'text' : 'password'}
              name="password"
              id="password"
              placeholder={capitalizeFirstLetter(
                formatMessage({ id: 'item-input' }, { item: formatMessage({ id: 'password' }) }, true)
              )}
              required
              value={password}
              onChange={(e) => handleInputChange(e)}
            />
            <InputGroupText onClick={toggleViewPassword}>
              {showPassword ? <FaEyeSlash color="#ADB5BD" size={20} /> : <FaEye color="#ADB5BD" size={20} />}
            </InputGroupText>
          </InputGroup>
        </FormGroup>
        {error && (
          <Alert color="danger" className="py-2">
            {formatMessage({ id: error })}
          </Alert>
        )}
        <Button className="w-100 w-sm-auto mt-2 m-0" color="primary" type="submit" disabled={!formValid}>
          {formatMessage({ id: 'proceed' })}
        </Button>

        <Button
          id="pwdReset"
          className="mt-2 ms-md-2 w-100 w-sm-auto text-decoration-underline text-muted"
          color="white"
          type="submit"
          disabled={loading}
          onClick={() => setPasswordResetForm(true)}
        >
          {formatMessage({ id: 'login-reset-password' })}
        </Button>
      </fieldset>
    </Form>
  );
}

export default LoginWithEmail;
