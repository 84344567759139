import { getAdditionalUserInfo, UserCredential } from 'firebase/auth';
import axiosInstance from './axios';
import { Locale } from '../i18n/i18n-config';

type Props = {
  userCredential: UserCredential;
  locale: Locale;
  pathname: string;
  router: any;
};

async function handleUserLoginOrSignup({ userCredential, locale, pathname, router }: Props) {
  if (!userCredential) return;

  // before proceeding => verify email
  if (!userCredential.user.emailVerified) {
    // email not verified => request new verification mail
    await axiosInstance.post(`/auth/request-email-verification-mail`, {
      language: locale,
      app: process.env.NEXT_PUBLIC_APP,
    });
    // notify user to take action
    return router.push(`/${locale}/auth/email-verification-sent`);
  }

  // user has verified email

  const { isNewUser } = getAdditionalUserInfo(userCredential) ?? {};

  if (isNewUser) router.push(`/${locale}/auth/register`);
  else {
    try {
      // verify if candidate exists
      await axiosInstance.get(`/candidate`);
      // candidate exists
      // userContext will load in the user as well => push to my-synergie
      const pathsWithoutRedirect = ['/jobs/[id]/[slug]']; // paths that don't prefer redirects can be added here
      if (!pathsWithoutRedirect.includes(pathname)) router.push(`/${locale}/my-synergie`);
    } catch (error) {
      // condidate doesn't exist => push to registration
      router.push(`/${locale}/auth/register`);
    }
  }
}

export default handleUserLoginOrSignup;
