'use client';

import { useEffect, useState } from 'react';
import {
  signInWithCustomToken,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  UserCredential,
} from 'firebase/auth';
import { Alert, Button } from 'reactstrap';
import { auth } from '../../utils/firebase';
import GoogleLogo from '../svg/social/GoogleLogo';
import FacebookLogo from '../svg/social/FacebookLogo';
import LinkedInLogo from '../svg/social/LinkedInLogo';
import WorkIDLogo from '../svg/social/WorkIDLogo';
import handleUserLoginOrSignup from '../../utils/handleUserLoginOrSignup';
import axiosInstance from '../../utils/axios';
import { Dictionaries, Locale } from '../../i18n/i18n-config';
import { getI18nUtils } from '../../i18n/i18n-utils';
import { usePathname, useParams, useRouter } from 'next/navigation';
import createPKCEPair from '../../utils/PKCE';

// centerButtons prop makes sure the buttons stay centered even when an errorMessage is displayed
// not necessary for the inline version (login while in applicationForm)
function LoginWithProvider({
  centerButtons,
  dictionary,
}: {
  centerButtons?: boolean;
  dictionary: Partial<Dictionaries>;
}): JSX.Element {
  const pathname = usePathname();
  const router = useRouter();
  const { locale }: { locale: Locale } = useParams();
  const { formatMessage } = getI18nUtils(dictionary);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [alertLink, setAlertLink] = useState<{ href: string; text: string } | null>(null);

  async function messageHandler(e) {
    const approvedSources = ['LinkedInOauth', 'WorkIDOauth'];
    if (e.isTrusted && e.data && e.data.source && approvedSources.includes(e.data.source)) {
      const { errorMessage, intlId, token } = e.data;
      // errorMessage is returned from the LinkedIn functionality and is already formatted.
      if (errorMessage) setError(errorMessage);
      // In case of WorkID we might receive an intlId which should be formatted here to incorporate a link to the workid signup page.
      if (intlId) {
        if (intlId === 'workid_user_not_found') {
          // Make sure the user is directed to the right language
          const workIDSignupURL =
            process.env.NEXT_PUBLIC_WORKID_SIGNUP_URL + `/workid?lang=${['nl', 'fr'].includes(locale) ? locale : 'en'}`;
          setAlertLink({ href: workIDSignupURL, text: process.env.NEXT_PUBLIC_WORKID_SIGNUP_URL! });
        }
        setError(formatMessage({ id: intlId }));
      }
      if (token) {
        const user = await signInWithCustomToken(auth, token);
        handleUserLoginOrSignup({ userCredential: user, pathname, locale, router });
      }
    }
  }

  useEffect(() => {
    // message will be sent from the popup page (/auth/linkedIn)
    window.addEventListener('message', messageHandler);
    return () => window.removeEventListener('message', messageHandler);
  }, []);

  async function loginWithLinkedIn() {
    // request the linkedIn redirect url (should resolve `https://www.${process.env.NEXT_PUBLIC_APP}/auth/linkedIn`)
    // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
    const windowReference = window.open('', '', 'width=400,height=585');
    const { data } = await axiosInstance.get(`/auth/getLinkedInRedirectURI`, {
      params: { app: process.env.NEXT_PUBLIC_APP },
    });
    if (windowReference) windowReference.location = data.url;
  }

  // Create a popup window, create a PKCE code pair and store the verifier to
  // use in the second request to workID, get the workID url and start the
  // authentication process.
  // Should resolve to `https://www.${process.env.NEXT_PUBLIC_APP}/auth/workID`
  async function loginWithWorkId() {
    const windowReference = window.open('', '', 'width=400,height=700');
    const { codeVerifier, codeChallenge } = await createPKCEPair();
    localStorage.setItem('workIDVerifier', codeVerifier);
    const app =
      process.env.NEXT_PUBLIC_APP === 'werkenbijsynergie.be' && locale === 'fr'
        ? 'travaillerchezsynergie.be'
        : process.env.NEXT_PUBLIC_APP;
    const { data } = await axiosInstance.get(`/auth/getWorkIdRedirectURI`, {
      params: { app, challenge: codeChallenge, locale },
    });

    windowReference!.location = data.url;
  }

  async function signInWithProvider(loginType) {
    setError(null);
    let provider: GoogleAuthProvider | null = null;
    let userCredential: UserCredential | null = null;
    setLoading(true);

    // @info: signin will trigger onIdTokenChanged in usercontext
    try {
      switch (loginType) {
        case 'Google':
          provider = new GoogleAuthProvider();
          userCredential = await signInWithPopup(auth, provider);
          break;
        case 'Facebook':
          provider = new FacebookAuthProvider();
          userCredential = await signInWithPopup(auth, provider);
          break;
        case 'LinkedIn':
          await loginWithLinkedIn();
          break;
        case 'workID':
          await loginWithWorkId();
          break;
        default:
          break;
      }
      if (userCredential) await handleUserLoginOrSignup({ userCredential, pathname, locale, router });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error && error.code && error.code === 'auth/account-exists-with-different-credential') {
        try {
          if (!error.email) throw new Error('no email in firebase error response');
          const providers = await fetchSignInMethodsForEmail(auth, error.email);
          if (providers.length > 0)
            setError(formatMessage({ id: 'login-error-wrong-loginmethod-provider-known' }, { provider: providers[0] }));
          else throw new Error('no other providers found');
        } catch (error) {
          setError(formatMessage({ id: 'login-error-wrong-loginmethod' }));
        }
      } else if (error.code === 'auth/popup-closed-by-user') setError(null);
      else setError(error?.message || formatMessage({ id: 'internal-server-error' }));
    }
  }

  const buttonClasses = 'd-inline-block social text-dark me-2';

  return (
    <div id="providers">
      <fieldset disabled={loading}>
        <div className={centerButtons ? 'd-flex justify-content-center align-items-center' : ''}>
          <Button
            color="light"
            className={buttonClasses}
            name="Google"
            onClick={() => signInWithProvider('Google')}
            disabled={loading}
          >
            <div className="iconContainer d-inline-block">
              <GoogleLogo size={{ width: 25, height: 25 }} />
            </div>
          </Button>

          <Button
            color="light"
            className={buttonClasses}
            name="Facebook"
            onClick={() => signInWithProvider('Facebook')}
            disabled={loading}
          >
            <div className="iconContainer d-inline-block">
              <FacebookLogo size={{ width: 25, height: 25 }} />
            </div>
          </Button>

          <Button
            className={buttonClasses}
            color="light"
            name="LinkedIn"
            disabled={loading}
            onClick={() => signInWithProvider('LinkedIn')}
          >
            <div className="iconContainer d-inline-block">
              <LinkedInLogo size={{ width: 25, height: 25 }} />
            </div>
          </Button>

          <Button
            className={buttonClasses}
            color="light"
            name="workID"
            disabled={loading}
            onClick={() => signInWithProvider('workID')}
          >
            <div className="iconContainer d-inline-block">
              <WorkIDLogo size={{ width: 25, height: 25 }} />
            </div>
          </Button>
        </div>
      </fieldset>
      {error && (
        <Alert color="danger" className="sticky mt-3" isOpen={!!error} toggle={() => setError(null)}>
          {error}
          {alertLink && (
            <>
              &nbsp;
              <a href={alertLink.href} className="alert-link" target="_blank">
                {alertLink.text}
              </a>
            </>
          )}
        </Alert>
      )}
    </div>
  );
}

export default LoginWithProvider;
