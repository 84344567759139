'use client';

import { useEffect, useState } from 'react';
import { Input, Form, Button, Alert } from 'reactstrap';

import validateEmailAddress from 'shared/utils/validateEmailAddress';
import axiosInstance from 'shared/utils/axios';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { Dictionaries, Locale } from '../../i18n/i18n-config';
import { getI18nUtils } from '../../i18n/i18n-utils';

type Props = {
  emailAddress?: string;
  abort: string | (() => void);
  dictionary: Partial<Dictionaries>;
  locale: Locale;
};

const SharedPasswordResetRequest = ({ emailAddress, abort, dictionary, locale }: Props): JSX.Element => {
  const { formatMessage } = getI18nUtils(dictionary);
  const pathName = usePathname();
  const searchParams = useSearchParams();
  const queryParamEmail = searchParams.get('email');
  const { push } = useRouter();
  const [email, setEmail] = useState({
    value: emailAddress || '',
    touched: false,
    valid: emailAddress ? !!validateEmailAddress(emailAddress) : false,
  });
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (queryParamEmail && queryParamEmail === 'string' && !email.value) {
      setEmail((prev) => ({
        ...prev,
        value: typeof queryParamEmail as string,
        valid: typeof queryParamEmail === 'string' ? validateEmailAddress(queryParamEmail) : false,
      }));
    }
  }, [queryParamEmail]);

  async function submitHandler(e) {
    e.preventDefault();

    try {
      setLoading(true);
      await axiosInstance.post(`/auth/password-reset`, {
        email: email.value,
        app: process.env.NEXT_PUBLIC_APP,
        lng: locale,
        path: pathName,
      });
      setEmailSent(true);
      setLoading(false);
    } catch (error) {
      setEmailSent(true);
      setLoading(false);
    }
  }

  function inputChangeHandler({ target }) {
    setEmail({ value: target.value, touched: true, valid: validateEmailAddress(target.value) });
  }

  return (
    <div id="passwordResetRequest">
      {error && (
        <Alert color="danger" isOpen={!!error} toggle={() => setError(null)}>
          {error}
        </Alert>
      )}
      <div>
        {/* <FormattedMessage id="password-reset-request-title" tagName="p" /> */}
        <p>{formatMessage({ id: 'password-reset-request-subtitle' })}</p>
        {emailSent ? (
          <Alert className="mt-2 " color="success">
            {formatMessage({ id: 'password-reset-email-sent' })}
          </Alert>
        ) : (
          <Form onSubmit={(e) => submitHandler(e)} className="mt-2">
            <fieldset disabled={loading}>
              <div className={`inputWithIconContainer${loading ? ' loading' : ''} mb-3`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="#707070"
                    d="M460.6 147.3L353 256.9c-.8.8-.8 2 0 2.8l75.3 80.2c5.1 5.1 5.1 13.3 0 18.4-2.5 2.5-5.9 3.8-9.2 3.8s-6.7-1.3-9.2-3.8l-75-79.9c-.8-.8-2.1-.8-2.9 0L313.7 297c-15.3 15.5-35.6 24.1-57.4 24.2-22.1.1-43.1-9.2-58.6-24.9l-17.6-17.9c-.8-.8-2.1-.8-2.9 0l-75 79.9c-2.5 2.5-5.9 3.8-9.2 3.8s-6.7-1.3-9.2-3.8c-5.1-5.1-5.1-13.3 0-18.4l75.3-80.2c.7-.8.7-2 0-2.8L51.4 147.3c-1.3-1.3-3.4-.4-3.4 1.4V368c0 17.6 14.4 32 32 32h352c17.6 0 32-14.4 32-32V148.7c0-1.8-2.2-2.6-3.4-1.4z"
                  />
                  <path
                    fill="#707070"
                    d="M256 295.1c14.8 0 28.7-5.8 39.1-16.4L452 119c-5.5-4.4-12.3-7-19.8-7H79.9c-7.5 0-14.4 2.6-19.8 7L217 278.7c10.3 10.5 24.2 16.4 39 16.4z"
                  />
                </svg>
                <Input
                  autoComplete="email"
                  type="text"
                  placeholder="E-mail"
                  value={email.value}
                  onChange={(e) => inputChangeHandler(e)}
                />
              </div>

              <Button className="w-100 w-sm-auto mt-2 m-0" color="primary" type="submit" disabled={!email.valid}>
                {formatMessage({ id: 'password-reset-request-send' })}
              </Button>

              <Button
                id="pwdReset"
                className="mt-2 ms-md-2 w-100 w-sm-auto text-decoration-underline text-muted"
                color="white"
                type="submit"
                disabled={loading}
                onClick={typeof abort === 'string' ? () => push(abort) : () => abort()}
              >
                {formatMessage({ id: 'login' })}
              </Button>
            </fieldset>
          </Form>
        )}
      </div>
    </div>
  );
};

export default SharedPasswordResetRequest;
